import React, { useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { ContactContent, Header } from './Elements'
import { ContactButton } from '../MainSection/Elements'
import { Feather7 } from '../../images/Feathers'
import { ContactGrid1, ContactGrid2 } from './Elements'
import { Logo } from '../Navbar/Elements'
import TextField from '@mui/material/TextField';
import { Contact } from '../../images/Contact'
import './contact.css'
import LogoSvg from '../../images/Logo'
import { FaTimes } from 'react-icons/fa';
import emailjs from '@emailjs/browser';

const initialData = {
    name: '',
    email: '',
    message: '',
    isSubmit: false,
}
export default function ContactDialog({ open, handleClose }) {

    const formRef = useRef();

    const [formData, setFormData] = useState(JSON.parse(JSON.stringify(initialData)))

    const [errors, setErrors] = useState({
        name: { error: false, msg: '' },
        email: { error: false, msg: '' },
        message: { error: true, msg: '' },
    })

    const fillData = (e, key) => {
        let inputVal = e.target.value;
        setErrors((prev) => ({ ...prev, [key]: { error: '', msg: false } }))
        setFormData((prev) => ({ ...prev, [key]: inputVal }))
        if (!errors.name.error && !errors.email.error && !errors.message.error) {
            console.log('if');
            document.getElementById('btn1').className = 'submitBtnEnabled submitBtn1'
            document.getElementById('btn2').className = 'submitBtnEnabled submitBtn2'
        }
        else {
            console.log('else');
            document.getElementById('btn1').className = 'submitBtnDisabled submitBtn1'
            document.getElementById('btn2').className = 'submitBtnDisabled submitBtn2 '
        }
    }

    const validateInput = (e, key) => {
        let inputVal = e.target.value;
        let errorMsg, errorVal;

        if (key === 'email') {
            let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (inputVal.match(validRegex)) {
                errorMsg = '';
                errorVal = false
            }
            else {
                errorMsg = 'enter a valid email address';
                errorVal = true
            }
        }
        if (key === 'name') {
            let validRegex = inputVal.length;
            if (validRegex > 3) {
                errorMsg = '';
                errorVal = false
            }
            else {
                errorMsg = 'name should be greater than 3 words';
                errorVal = true
            }
        }
        if (key === 'message') {
            let validRegex = inputVal.length;
            if (validRegex > 10) {
                errorMsg = '';
                errorVal = false
            }
            else {
                errorMsg = 'Message should be greater than 10 words';
                errorVal = true
            }
        }
        setErrors((prev) => ({ ...prev, [key]: { error: errorVal, msg: errorMsg } }))
    }

    const onSubmit = () => {
        sendEmail();
        if (!errors.name.error && !errors.email.error && !errors.message.error)
            setFormData({
                name: '',
                email: '',
                message: '',
                isSubmit: true
            })
        closeDialog();
    }

    const sendEmail = () => {
        emailjs.sendForm(process.env.REACT_APP_SERVICE_KEY, process.env.REACT_APP_TEMPLATE_KEY, formRef.current, process.env.REACT_APP_PUBLIC_KEY)
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
    };
    
    const closeDialog = () => {
        setFormData(JSON.parse(JSON.stringify(initialData)))
        setErrors({
            name: { error: false, msg: '' },
            email: { error: false, msg: '' },
            message: { error: true, msg: '' },
        })
        handleClose()
    }

    return (
        <Dialog
            className='bg'
            maxWidth
            open={open}
            onClose={closeDialog}
        >
            <DialogContent className="dialogContent">
                <div className="closeIcon" style={{color:'#1E1E1E'}} onClick={closeDialog}><FaTimes /></div>
                <Header><Logo className='contactLogo'><LogoSvg color={'#FEFAE0'} /></Logo></Header>
                <ContactContent className='contactForm'>
                    {/* <ContactButton href='https://rdj4l7hx3mb.typeform.com/to/GNnubGVs' target='_blank'>Contact</ContactButton> */}
                    <ContactGrid1 className={formData.isSubmit ? 'ContactGrid1D' : 'ContactGrid1 ContactGrid1D'}>
                        <div className='contactHeadForm'>
                            <div className='contactHeadDialog contactHeadM'>Have a project ?</div>
                            <div className='contactSubHeadDialog contactSubHead'>We will be happy to have a discussion.</div>
                            {!formData.isSubmit && (<ContactButton id='btn1' className='submitBtn1 submitBtnDisabled' onClick={onSubmit}>Submit</ContactButton>)}
                        </div>
                    </ContactGrid1>
                    {!formData.isSubmit ? (
                        <ContactGrid2 className='ContactGrid2'>
                            <form ref={formRef}>
                                <div className='inputPart1'>
                                    <TextField
                                        value={formData.name}
                                        placeholder='Name'
                                        className='name'
                                        InputProps={{
                                            className: 'inputField'
                                        }}
                                        onBlur={(e) => validateInput(e, 'name')}
                                        helperText={errors.name.msg}
                                        error={errors.name.error}
                                        autoComplete='off'
                                        label=""
                                        onChange={(e) => fillData(e, 'name')}
                                        name="from_name"
                                    />
                                    <TextField
                                        InputProps={{
                                            className: 'emailInput inputField',
                                        }}
                                        autoComplete='off'
                                        className='email'
                                        value={formData.email}
                                        onBlur={(e) => validateInput(e, 'email')}
                                        placeholder='Email'
                                        helperText={errors.email.msg}
                                        error={errors.email.error}
                                        onChange={(e) => fillData(e, 'email')}
                                        label=""
                                        name="from_email"
                                    />
                                </div>
                                <div>
                                    <TextField
                                        fullWidth
                                        InputProps={{
                                            className: 'msgInputProps',
                                        }}
                                        autoComplete='off'
                                        className='msgInput'
                                        value={formData.message}
                                        placeholder='Message'
                                        label=""
                                        onBlur={(e) => validateInput(e, 'message')}
                                        helperText={errors.message.msg}
                                        error={errors.message.error}
                                        onChange={(e) => fillData(e, 'message')}
                                        multiline
                                        rows={4}
                                        name="message"
                                    />

                                    {!formData.isSubmit && (<ContactButton id='btn2' className='submitBtn2 submitBtnDisabled' onClick={onSubmit}>Submit</ContactButton>)}
                                </div>
                            </form>
                        </ContactGrid2>
                    ) : (
                        <ContactGrid2 className='customContactbg'>
                            <Contact />
                            <div className='feather7'>
                                <Feather7 />
                            </div>
                        </ContactGrid2>
                    )}
                </ContactContent>
            </DialogContent>
        </Dialog>
    );
}