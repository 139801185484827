import React from 'react'
import { ServiceContainer, ServiceWrapper, ContentHead, Testimonial, TestimonialHead } from './Elements'
import Grid from '@mui/material/Grid';
import ServiceBg from '../../images/Service.js'
import { Feather6, Feather5 } from '../../images/Feathers'
import './service.css'
import { services, testimonials } from '../Constants'

const testimonialName = {
    background: '#FAEDCD',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '25px',
    color: '#1E1E1E'
};

const testimonialDesg = {
    background: '#FAEDCD',
};

const testimonialLogo = {
    background: '#FAEDCD',
    display: 'flex',
    justifyContent: 'end'
};



export const ServicesSection = () => {
    return (
        <>
            <ServiceContainer id='services'>
                <ServiceWrapper style={{padding: '0 24px'}}>

                    <ContentHead className='contentHead'>Our Services
                        <div className='feather5'>
                            <Feather5 />
                        </div>
                    </ContentHead>
                    <Grid container className='serviceGridMain1' spacing={2}>
                        <Grid className='bgGrid' item xs={5}><ServiceBg height={'400'} /></Grid>
                        <Grid className='serviceGrid' item xs={7}>
                            {services.map((row, idx) => {
                                return (
                                    <div key={idx} className='row'>
                                        <row.logo />
                                        <div className='rowContainer'>
                                            <div className='rowHeading'>{row.heading}</div>
                                            <div className='rowDesc'>{row.description}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </Grid>
                    </Grid>
                    <div className='feather6'>
                        <Feather6 />
                    </div>
                </ServiceWrapper>
                {/* <ServiceWrapper>

                    <ContentHead className='testimonialHead'>Testimonials</ContentHead>
                    <Grid container className='serviceGridMain2'>
                        {testimonials.map((row, idx) => {
                            return (
                                <Grid key={idx} className='gridStyleTest1' item xs={6} style={{justifyContent: idx % 2 === 0 ? 'flex-start' : 'flex-end'}}>
                                    <Testimonial>
                                        <TestimonialHead> {row.content}
                                        </TestimonialHead>
                                        <Grid container>
                                            <Grid  xs={9} item>
                                                <div style={testimonialName}>{row.baseHead}</div>
                                                <div style={testimonialDesg}>{row.desg}</div>
                                            </Grid>
                                            <Grid style={testimonialLogo} xs={3} item>
                                                <row.logo />
                                            </Grid>
                                        </Grid>
                                    </Testimonial>
                                </Grid>
                            )
                        })}
                    </Grid>
                </ServiceWrapper> */}
            </ServiceContainer>
        </>
    )
}
