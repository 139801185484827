import React, { useEffect, useState } from 'react'
import { Nav, NavContainer, Logo, NavMenu, NavItem, NavLinks, SubNavContainer, MobileIcon } from './Elements'
import { animateScroll as scroll } from 'react-scroll'
import LogoSvg from '../../images/Logo'
import { FaBars } from 'react-icons/fa';

export const Navbar = ({ toggle }) => {
    const [scrollNav, setScrollNav] = useState(false)

    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, [])

    const scrollToTop = () => {
        scroll.scrollToTop();
    }
    return (
        <>
            <Nav scrollNav={scrollNav}>
                <NavContainer>
                    <Logo to='/' onClick={scrollToTop}><LogoSvg color={"#CCD5AE"} /></Logo>
                    <MobileIcon ><FaBars onClick={toggle} ></FaBars></MobileIcon>
                    <SubNavContainer>
                        <NavMenu>
                            <NavItem>
                                <NavLinks to='about' smooth={true} duration={700} spy={true} exact='true' offset={-80}>
                                    About
                                </NavLinks>
                            </NavItem>
                        </NavMenu>
                        <NavMenu>
                            <NavItem>
                                <NavLinks to='services' smooth={true} duration={700} spy={true} exact='true' offset={-80}>
                                    Services
                                </NavLinks>
                            </NavItem>
                        </NavMenu>
                        <NavMenu>
                            <NavItem>
                                <NavLinks to='contact' smooth={true} duration={700} spy={true} exact='true' offset={-80}>
                                    Contact
                                </NavLinks>
                            </NavItem>
                        </NavMenu>
                    </SubNavContainer>
                </NavContainer>
            </Nav>
        </>
    )
}
