import styled from 'styled-components'

export const FooterContainer = styled.div`
color: #1E1E1E;
`
export const FooterContent = styled.div`
display:flex;
align-items: center;
list-style: none;
margin-left: 10vw;
height: 10vh;
padding: 0 24px; 
background: #FEFAE0;

@media screen and (max-width:480px) {
    margin-left: 2vw;
}
`
export const FooterDesc = styled.div`
width:100%;
position: relative;
font-weight: 400;
font-size: 16px;
line-height: 19px;
text-transform: uppercase;

@media screen and (max-width:480px) {
    font-size: 14px;
}
`