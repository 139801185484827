import styled from 'styled-components'


export const MainContainer = styled.div`
background: #FEFAE0;
display: flex;
justify-content: center;
align-items: center;
padding :0 30px;
height: 880px;
position: relative;
z-index: 1;


@media screen and (max-width:480px){
    margin: 0;
    padding :0 ;
    height: 770px;
}

@media screen and (min-width:480px) and (max-width: 560px){
    height: 650px;
}

@media screen and (min-width:560px) and (max-width: 980px){
    height: 650px;
}

@media screen and (min-width:980px) and (max-width: 1300px) {
    height: 740px;
}

@media screen and (min-width:1300px) and (max-width:1650px){
    margin-top: 0;
    height: 780px;
}

@media screen and (min-width: 540px) and (max-width: 540px) {
    height: 500px;
  }

`
export const Background = styled.div`
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
width: 100%;
height: 100%;
overflow: hidden;
`
export const MainContent = styled.div`
// display: grid;
z-index:1;
height: 880px;
width: 100%;
max-width: 90vw;
margin-right: auto;
margin-left: auto;
margin-top: 10vh;
padding : 0px 90px 0px 30px;
justify-content: center;
position: relative;

@media screen and (max-width:480px){
    margin-top: 3vh;
    height: 710px;
    padding : 0px 50px 0 34px;
    max-width: 100%;
    margin-right: 0;
    margin-left: 0;
}

@media screen and (min-width:768px) and (max-width: 1300px) {
    height: 650px;
}

@media screen and (min-width:1300px) and (max-width:1550px){
    margin-top: 0;
    height: 680px;
}

@media screen and (min-width:480px) and (max-width: 548px){
    margin-top: 3vh;
    height: 500px;
    padding : 0px 20px 0px 30px;
}

@media screen and (min-width:548px) and (max-width: 748px){
    margin-top: 3vh;
    height: 700px;
    padding : 0px 110px 0px 30px;
}

@media screen and (min-width:748px) and (max-width: 980px){
    margin-top: 3vh;
    height: 500px;
    padding : 0px 20px 0px 30px;
}

@media screen and (min-width: 540px) and (max-width: 540px) {
    height: 500px;
  }
`

export const MainDesc = styled.span`
font-weight: bold;
font-size: 58px;
margin-bottom: 20px;
line-height: 81px;
/* height: 20vh; */

@media screen and (max-width:480px){
    height: 15vh;
    font-size: 34px;
    margin-bottom: 10px;
    line-height: 51px;
    transition 0.8s all ease;
}

@media screen and (min-width:480px) and (max-width: 548px){
    height: 20vh;
    font-size: 32px;
    margin-bottom: 15px;
    line-height: 40px;
    transition 0.8s all ease;
}

@media screen and (min-width:548px) and (max-width:826px){
    height: 15vh;
    font-size: 34px;
    margin-bottom: 10px;
    line-height: 51px;
    transition 0.8s all ease;
}

@media screen and (min-width:480px) and (max-width:1600px){
    /* height: 25vh; */
    transition 0.8s all ease;
}

@media screen and (min-width:480px)and (max-width: 980px){
    height: 15vh;
}

@media screen and (min-width:820px) and (max-width:1400px){
    font-size: 45px;
}

@media screen and (max-width:300px) {
    height: 20vh;
    font-size: 30px;
    margin-bottom: 10px;
    line-height: 40px;
}


@media screen and (min-height:1500px)and (max-height:3000px) {
    height: 10vh;
}
`

export const ContactButton = styled.a`
color: #1E1E1E;
width: 207px;
height: 65px;
border-radius: 20px;
border: none;
font-size: 21px;
margin-top: 40px;
cursor: pointer;
text-decoration: none;
display: flex;
text-align: center;
align-items: center;
justify-content: center;

@media screen and (max-width:768px){
    width: 100%;
    height: 40px;
    border-radius: 16px;
    font-size: 14px;
    transition 0.8s all ease;
}

@media screen and (min-width:768px) and (max-width:1300px){
    width: 180px;
    font-size: 18px;
    height: 55px;
    transition 0.8s all ease;
}
`

export const ScrollView = styled.div`
position : absolute;
width: 100%;
bottom:0;
`
export const ScrollViewContent = styled.div`
background : #E9EDC9;
height: 70px;
z-index: 10;
font-weight: 400;
font-size: 28px;
line-height: 34px;
display: flex;
align-items: center;
white-space: nowrap;
overflow-x: hidden;

@media screen and (max-width:768px){
    height: 40px;
    font-size: 18px;
    line-height: 18px;
}

@media screen and (min-width:768px) and (max-width:1300px){
    height: 60px;
    font-size: 23px;
    line-height: 18px;
}
`