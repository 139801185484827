import React, { useState } from 'react'
import {
    MainContainer, MainContent, ContactButton, ScrollView,
    MainDesc, ScrollViewContent
} from './Elements'
import MainBg from '../../images/Main.js'
import { Grid } from '@mui/material';
import { Feather1, Feather2 } from '../../images/Feathers.js'
import ReactTypingEffect from "typewriter-effect";
import './main.css';
import ContactDialog from '../ContactSection/ContactDialog';
// import Marquee from "react-fast-marquee";

const MainContentDesc = () => {
    return (
        <>
            <ReactTypingEffect
                options={{
                    autoStart: true,
                    loop: true,
                }}
                onInit={(typewriter) => {

                    typewriter
                        .typeString('<span class="customFont"> We <span class="customFont" style="color:#94785b;">Build</span></span>')
                        .pauseFor(300)
                        .deleteAll(1)
                        .typeString('<span class="customFont">We <span class="customFont" style="color:#94785b;">Assess</span></span>')
                        .pauseFor(300)
                        .deleteAll(1)
                        .typeString('<span class="customFont">We <span class="customFont" style="color:#94785b;">Fortify</span>')
                        .pauseFor(300)
                        .deleteAll(1)
                        .start();
                }}
            />
        </>
    );
};

export const MainSection = () => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <MainContainer id='home'>
                <MainContent>
                    <Grid container className='gridMain' spacing={2}>
                        <Grid item xs={7} className='grid1'>
                            <MainDesc className='customFont'>
                                <MainContentDesc />
                            </MainDesc>
                            {/* <div className='subHead'>Diam vulputate pellentesque duis varius laoreet.</div> */}
                            {/* <Link to='/contact'><ContactButton href='https://rdj4l7hx3mb.typeform.com/to/GNnubGVs' target='_blank'>Contact</ContactButton></Link> */}
                            <ContactButton className="mainContactBtn" onClick={handleClickOpen}>Contact</ContactButton>
                        </Grid>
                        <Grid item xs={5} style={{ position: 'relative' }}>
                            <div className='feather1'>
                                <Feather1 />
                            </div>
                            <div className='MainBg'>
                                <MainBg />
                            </div>

                        </Grid>
                    </Grid>

                </MainContent>

                <ScrollView>
                    <div className='feather2'>
                        <Feather2 />
                    </div>

                    <ScrollViewContent>
                        {/* <Marquee style={{ height: '70px' }} speed={100} play lopp={0} gradient={false}>
                        <span>HR Tech · Health and Wellness · Gaming · Ed Tech · HR Tech · Health and Wellness · Gaming · Ed Tech ·HR Tech · Health and Wellness · Gaming · Ed Tech · &nbsp;</span>
                    </Marquee> */}

                        <div className="marquee">
                            <div className="marquee-inner">
                                <span>
                                    <div>Health and Wellness . Gaming . HR Tech . IoT . eCommerce . Finance . Energy . Electric Automotive . Gaming .</div>
                                </span>
                                <span>
                                    <div> &nbsp; Health and Wellness . Gaming . HR Tech . IoT . eCommerce . Finance . Energy . Electric Automotive . &nbsp;</div>
                                </span>
                            </div>
                        </div>
                        {/* <p class="marquee">
                            <span>Health and Wellness . Gaming . HR Tech . IoT . eCommerce . Finance . Energy . Electric Automotive &nbsp;</span>
                        </p>
                        <p class="marquee marquee2">
                            <span>Health and Wellness . Gaming . HR Tech . IoT . eCommerce . Finance . Energy . Electric Automotive &nbsp;</span>
                        </p> */}

                    </ScrollViewContent>
                </ScrollView>
                <ContactDialog open={open}
                    handleClose={handleClose} />
            </MainContainer >
        </>
    )
} 
