import styled from 'styled-components'

export const ContactContainer = styled.div`
color: #1E1E1E;
`
export const ContactContent = styled.div`
postion: relative;
margin-top: 8vw;
padding: 0 24px;
height: 450px;
background: #CCD5AE;
width: 100%;
display: flex;

@media screen and (max-width:480px){
    height: 400px;
    padding: 34px;
    padding-bottom: 0px;
    flex-direction: column;
}

@media screen and (min-width: 540px) and (max-width: 540px) {
    height: 64vh;
  }

`

export const Header = styled.div`
background : #CCD5AE;
height: 60px;
display: flex;
position: sticky;
top: 0;
z-index: 10;`


export const ContactGrid1 = styled.div`
display: flex;
align-items: center;
width:42%;

@media screen and (max-width:480px){
  width: 100%;
}
`
export const ContactGrid2 = styled.div`
position: relative;
width:50%;

@media screen and (max-width:480px){
  width: 62vw;
  height: 36vh;
}`