import React from 'react'
import { AboutContainer, AboutWrapper, AboutContent, ContentHead } from './Elements'
import Grid from '@mui/material/Grid';
import { LogoConnelly, LogoHansen, LogoReynolds } from '../../images/Logo'
import { Feather4, Feather3 } from '../../images/Feathers'
import './about.css'


export const AboutSection = () => {
    return (
        <AboutContainer id='about'>
            
            <AboutWrapper>
            <div className='feather3'>
                    <Feather3 />
                </div>
                <p style={{ textAlign: 'justify', fontSize: 24, lineHeight: 1.4 }}> Project MIMIR works with globally distributed tech enthusiasts and experts so the solution fits the problem. We work with tech teams and startups to make immediately available the technical and organisational know-how needed to roadmap and realise product ideas.</p>
            </AboutWrapper>
            {/* <div className='feather4'> 
                <Feather4 />
            </div> */}
            {/* <AboutContent>
                <ContentHead>Our Clients</ContentHead>
                <Grid container spacing={2} className='gridAbout'>
                    <Grid className='gridStyle'><div className='logoConnelly'><LogoConnelly /></div></Grid>
                    <Grid className='gridStyle'><div className='logoHansen'><LogoHansen /></div></Grid>
                    <Grid className='gridStyle'><div className='logoReynolds'><LogoReynolds /></div></Grid>
                </Grid>
            </AboutContent> */}
        </AboutContainer>
    )
}
