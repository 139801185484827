import styled from 'styled-components'
import { Link as LinkS } from 'react-scroll'


export const SidebarContainer = styled.aside`
position: fixed;
z-index:  ${({ isOpen }) => (isOpen ? '9998' : '-1')};
width: 100%;
height: 100%;
background: transparent;
right: 0;
transition: 0.2s ease-in-out;
opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
top: ${({ isOpen }) => (isOpen ? '0' : '100%')};

`

export const SidebarWrapper = styled.div`
color: #1E1E1E;
margin-bottom: -30px;
background: #FAEDCD;
width: 96%;
height: 21%;
display: grid;
align-items: center;
right: 2vw;
top: 7vh;
position: fixed;
box-shadow: 0 0 2px 0 rgb(145 158 171 / 24%), 0 20px 40px -4px rgb(145 158 171 / 24%);
border-radius: 8px;
`
export const SidebarMenu = styled.ul`
display: grid;
grid-template-columns: 1fr;
grid-template-rows: repeat(3, 50px);
text-align: center;
margin-bottom:-20px;
-webkit-tap-highlight-color: transparent;

// @media screen and (max-width: 480px){
// grid-template-rows: repeat(3, 60px);
// }
`
export const SidebarLink = styled(LinkS)`
display: : flex;
align-items: center;
justify-content:center;
font-size:  1rem;
text-decoration: none;
list-style: none;
transition : 0.2s ease-in-out
color: #1E1E1E;
cursor: pointer;

&:hover {
    color: #696E59;
}
`