import styled from 'styled-components'
import { Link as ScrollLink } from 'react-scroll'


export const Nav = styled.nav`
background : #FEFAE0;
height: 80px;
// margin-top: -80px;
display: flex;
justify-content: center;
align-items: center;
font-size: 1rem;
position: sticky;
top: 0;
z-index: 10;

@media screen and (max-width:480px) {
    height: 60px;
}

@media screen and (max-width:960px){
    transition 0.8s all ease;
}

`

export const NavContainer = styled.div`
display: flex;
height: 80px;
z-index: 1;
width: 100%;
padding :0 24px;

@media screen and (max-width:480px) {
padding :0 34px;
height: 60px;
}

@media screen and (min-width:480px) and (max-width:1300px) {
    height: 70px;
}
`
export const SubNavContainer = styled.div`
display: flex;
justify-content: end;
height: 80px;
z-index: 1;
width: 100%;
margin-right: 8vw;
`
export const Logo = styled.h1`
color: #1E1E1E;
justify-self: flex-start;
cursor: pointer;
font-size: 1.5rem;
display: flex;
align-items: center;
margin-left: 10vw;
text-decoration: none;
-webkit-tap-highlight-color: transparent;

@media screen and (max-width:480px) {
    margin-left: 0;
    width: 32vw;
}

@media screen (max-width:768px){
    margin-left: 0;
}

@media screen and (min-width:480px) and (max-width:700px) {
    width: 22vw;
}

@media screen and (min-width:1024px) and (max-width:1300px) {
    width: 10vw;
}
`
export const MobileIcon = styled.div`
display :none;
@media screen and (max-width:600px){
display: block;
position: absolute;
top: -6px;
right: 0;
transform: translate(-100%, 60%);
font-size: 1.8rem;
cursor: pointer;
z-index: 9998;
-webkit-tap-highlight-color: transparent;
color:#1e1e1ebd;
}
`

export const NavMenu = styled.ul`
display: flex;
align-items: center;
list-style: none;
text-align: center;
margin-right: 0px;

@media screen and (max-width:600px){
    display:none;
}
`
export const NavItem = styled.li`
height: 80px;
`
export const NavLinks = styled(ScrollLink)`
color: #1E1E1E;
display: flex;
align-items: center;
text-decoration: none;
padding: 0 1rem;
height: 100%;
cursor: pointer;
font-size: 21px;

@media screen and (min-width:480px) and (max-width:1300px) {
    font-size: 19px;
}

&:hover {
    color: #696E59;
}
`