import styled from 'styled-components'

export const ServiceContainer = styled.div`
color: #1E1E1E;
`
export const ServiceWrapper = styled.div`
    margin-left: 10vw;
    margin-right: 10vw;
    padding: 0 24px;

    @media screen and (max-width:768px){
        padding: 0 ;
    }
    
`
export const ContentHead = styled.div`
width: 100%;
color: #866646;
font-weight: 600;
font-size: 50px;
line-height: 61px;
text-align: center;
position: relative;

@media screen and (max-width:768px){
    font-weight: 600;
    font-size: 35px;
    line-height: 61px;
}

@media screen and (min-width:768px) and (max-width:1300px){
    font-size: 44px;
}
`
export const Testimonial = styled.div`
width: 543px;
height: 339px;
background: #FAEDCD;
border-radius: 20px;
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 60px 77px;
font-weight: 400;
font-size: 21px;
line-height: 25px;
color: #1E1E1E;

@media screen and (max-width:300px) {
    padding: 15px 15px;
    font-size: 15px;
    line-height: 20px;
}

@media screen and (min-width:300px) and (max-width:410px){
    padding: 24px 32px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

@media screen and (min-width:410px) and (max-width:480px){
    line-height: 28px;
    padding: 24px 32px;
    font-weight: 400;
    font-size: 16px;
}

@media screen and (min-width:480px) and (max-width:680px){
    padding: 26px 26px;
    line-height: 24px;
    font-weight: 400;
    font-size: 18px;
}

@media screen and (min-width:680px) and (max-width:1080px){
    padding: 30px 35px;
    font-weight: 400;
    font-size: 20px;
}

@media screen and (min-width:1080px) and (max-width:1300px){
    font-size: 18px;
    width: 450px;
    height: 339px;
}
`
export const TestimonialHead = styled.div`
background: #FAEDCD;
margin-bottom: 40px;

@media screen and (min-width:200px) and (max-width:390px) {
    margin-bottom: 24px;
}

@media screen and (min-width:390px) and (max-width:500px) {
    margin-bottom: 34px;
}

// font-size: 35px;
`