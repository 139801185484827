import React from 'react'
import { FooterContainer, FooterContent, FooterDesc } from './Elements'
import { Feather8 } from '../../images/Feathers'
import './footer.css'


export const Footer = () => {
    return (
        <>
            <FooterContainer id='Footer'>
                <FooterContent>
                    <FooterDesc>
                        © 2022 project mimir
                        <div className='feather8'>
                            <Feather8 />
                        </div>
                    </FooterDesc>

                </FooterContent>
            </FooterContainer>
        </>
    )
}
