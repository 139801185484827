import React from 'react';

const MainBg = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M385.428 305.96L300.025 318.69C295.905 300.24 293.715 291.045 292.275 284.44C286.25 257 262.408 247.585 255.805 245.5C254.975 250.475 225.995 267.708 212.843 259.83C164.695 322.693 135 363.775 71.3701 424C61.5851 433.073 90.0851 464.23 99.9176 455.3C161.153 400.43 193.708 370.4 239.893 314.185L230.965 284.545L269.25 350.323C271.97 354.988 276.058 358.705 280.958 360.975C285.86 363.243 291.338 363.95 296.655 363.005L392.603 345.715C401.203 344.312 394.235 304.37 385.428 305.96Z" fill="#D4A373" />
            <path d="M422.142 175.578C421.29 175.815 411.142 178.658 405.712 180.133C408.392 177.318 411.425 174.861 414.732 172.822C421.382 168.547 420.337 165.175 411.885 168.975C410.98 169.402 401.575 173.44 398.775 174.627C397.065 175.34 392.6 176.628 391.697 175.34C389.037 171.635 390.652 160.34 386.615 159.712C384.002 159.285 383.615 167.028 382.767 172.253C382.245 175.293 377.827 177.953 374.36 178.19C374.205 178.19 371.517 178.488 362.532 179.378C359.782 187.358 361.06 201.228 365.667 207.973C387.012 206.25 412.345 202.115 419.34 200.658C427.747 198.853 428.365 196.145 418.532 196.288C414.8 196.341 411.075 195.991 407.417 195.243C408.132 195.243 414.067 193.438 421.192 191.243C429.692 188.63 430.17 185.163 419.91 186.635C416.25 187.217 412.547 187.468 408.842 187.385C409.365 187.195 418.152 183.49 424.755 179.928C432.212 175.958 430.977 172.87 422.142 175.578Z" fill="#D4A373" />
            <path d="M244.31 178C241.127 176.29 238.04 173.155 238.185 162.138C238.307 157.262 238.799 152.404 239.655 147.603C246.257 145.418 243.277 137.713 238.8 140.715C238.175 141.191 237.466 141.545 236.71 141.76C236.57 141.855 235.855 141.902 235.665 140.667C233.825 129.852 225.165 124.698 218.377 140.418C214.53 151.248 213.96 168.535 225.835 166.682C227.72 166.294 229.562 165.721 231.335 164.972L226.775 170.152C226.825 171.337 225.492 177.702 224.07 178.51C221.172 180.173 216.137 181.74 207.112 181.313C223.215 186.49 240.882 185.968 259.692 181.313C254.402 181.192 249.182 180.068 244.31 178Z" fill="#D4A373" />
            <path d="M150.788 129.552C149.125 123.52 147.415 116.252 146.75 113.117C145.895 109.032 144.423 95.6375 142.095 98.725C140.508 100.71 139.868 107.725 139.503 111.297L122.668 114.922C123.984 122.221 126.086 129.356 128.938 136.202C135.851 132.914 143.215 130.673 150.788 129.552Z" fill="#D4A373" />
            <path d="M231.297 165.033L226.75 170.21C226.578 169.02 226.263 167.856 225.81 166.742C227.688 166.344 229.525 165.772 231.297 165.033Z" fill="#1E1E1E" />
            <path d="M264.355 210.205C262.925 214.066 261.75 218.017 260.84 222.033C266.73 222.268 267.11 222.41 272.715 222.41L264.355 210.205Z" fill="#1E1E1E" />
            <path d="M244.75 308.145C243.135 310.187 241.473 312.182 239.905 314.177L230.978 284.537L244.75 308.145Z" fill="#1E1E1E" />
            <path d="M249.345 136.013C234.25 118.723 225.027 126.703 220 136.583C225.32 126.513 233.725 129.553 235.625 140.668C235.815 141.95 236.527 141.855 236.67 141.76C237.431 141.558 238.142 141.203 238.76 140.715C243.177 137.628 246.218 145.37 239.615 147.603C238.757 152.403 238.265 157.26 238.143 162.135C238.143 162.135 265.542 154.5 249.345 136.013Z" fill="#1E1E1E" />
            <path d="M266.825 131.785C261.22 137.628 252.445 138.328 247.207 133.305C241.97 128.283 242.267 119.483 247.872 113.64C253.477 107.798 262.252 107.098 267.49 112.12C272.727 117.143 272.43 125.943 266.825 131.785Z" fill="#1E1E1E" />
            <path d="M69.9996 433.065C41.1346 455.75 23.4996 465.145 23.9671 468.925C24.9671 477.048 54.7946 463.747 69.7546 464.982C73.2978 465.355 76.8801 465.033 80.2996 464.033C84.1471 462.845 87.0496 459.615 90.7496 455.863C81.2498 451.093 73.8563 442.97 69.9996 433.065Z" fill="#1E1E1E" />
            <path d="M415.5 312.372C404.977 305.077 400.5 307.622 390.85 310.14C395.367 319.477 397.225 329.88 396.217 340.205C408.422 338.497 422.862 336.737 431.792 336.205C444.76 335.455 456.11 334.308 457.015 332.168C460.25 324.675 427.75 321.015 415.5 312.372Z" fill="#1E1E1E" />
            <path d="M372.648 178.423L362.58 179.423C364.86 172.87 369.18 172.39 372.648 178.423Z" fill="#1E1E1E" />
            <path d="M374.55 207.308C372.317 212.248 368.755 212.438 365.715 208.02L374.55 207.308Z" fill="#1E1E1E" />
            <path d="M309.382 85.665C309.17 85.7105 308.95 85.7135 308.737 85.6735C308.525 85.6335 308.32 85.5512 308.14 85.432C307.957 85.3125 307.802 85.1582 307.682 84.9782C307.56 84.798 307.477 84.5955 307.435 84.3825L303.397 64.6325C303.31 64.2105 303.392 63.7707 303.627 63.41C303.865 63.0492 304.235 62.797 304.655 62.7087C305.077 62.6205 305.517 62.7037 305.88 62.9397C306.24 63.1757 306.492 63.5455 306.58 63.9675L310.617 83.7175C310.672 83.9267 310.685 84.1455 310.652 84.3595C310.62 84.5735 310.542 84.7785 310.425 84.9615C310.31 85.1442 310.157 85.3012 309.977 85.4222C309.797 85.5435 309.595 85.626 309.382 85.665Z" fill="#1E1E1E" />
            <path d="M171.307 98.25L156.962 31.6125L134.875 36.3625C133.729 36.6293 132.683 37.216 131.858 38.054C131.033 38.8923 130.463 39.9475 130.214 41.097C129.965 42.2465 130.048 43.443 130.453 44.5475C130.857 45.6518 131.567 46.6185 132.5 47.335C134.626 48.9735 136.24 51.1858 137.152 53.7108C138.063 56.236 138.234 58.969 137.644 61.588C137.054 64.2067 135.727 66.6025 133.821 68.493C131.915 70.3832 129.509 71.6895 126.885 72.258C124.261 72.8262 121.53 72.633 119.012 71.7007C116.495 70.7685 114.296 69.1362 112.675 66.9963C111.054 64.8563 110.079 62.2977 109.863 59.6217C109.648 56.946 110.202 54.2642 111.46 51.8925C112.009 50.856 112.253 49.685 112.163 48.5152C112.074 47.3455 111.653 46.2255 110.952 45.2852C110.25 44.345 109.296 43.6235 108.2 43.2043C107.105 42.7853 105.912 42.6858 104.762 42.9175L84.1475 47.335L98.5 113.972C98.8402 115.547 99.792 116.922 101.146 117.795C102.5 118.668 104.145 118.967 105.72 118.627L139.585 111.36C139.902 107.925 140.52 100.9 142.15 98.725C144.002 96.2575 145.332 104.475 146.235 109.887L166.66 105.47C168.233 105.127 169.606 104.175 170.477 102.821C171.348 101.468 171.647 99.8237 171.307 98.25Z" fill="#1E1E1E" />
            <path d="M200.612 203.27L193.112 221.555C194.822 221.697 196.58 221.792 198.29 221.887C197.338 215.586 198.142 209.144 200.612 203.27Z" fill="#1E1E1E" />
            <path d="M16.3196 272.045C15.9055 272.442 15.3527 272.665 14.778 272.665C14.2032 272.662 13.6512 272.44 13.2385 272.04C12.8258 271.64 12.5846 271.095 12.5657 270.52C12.5467 269.945 12.7516 269.387 13.1371 268.96C13.5513 268.562 14.104 268.34 14.6788 268.34C15.2536 268.342 15.8055 268.565 16.2182 268.965C16.631 269.365 16.8722 269.91 16.8911 270.485C16.91 271.06 16.7051 271.617 16.3196 272.045Z" fill="#1E1E1E" />
            <path d="M231.155 195.718C231.201 196.225 231.097 196.734 230.854 197.182C230.611 197.63 230.241 197.995 229.791 198.232C229.34 198.469 228.829 198.568 228.323 198.515C227.816 198.462 227.337 198.26 226.945 197.935C226.554 197.61 226.267 197.175 226.122 196.687C225.977 196.199 225.98 195.679 226.131 195.193C226.281 194.706 226.573 194.275 226.968 193.955C227.363 193.634 227.845 193.437 228.352 193.39C228.689 193.358 229.029 193.392 229.353 193.491C229.677 193.591 229.978 193.753 230.238 193.97C230.499 194.186 230.714 194.452 230.871 194.752C231.029 195.052 231.125 195.38 231.155 195.718Z" fill="#1E1E1E" />
            <path d="M226.975 213.055C227.022 213.562 226.917 214.071 226.674 214.519C226.432 214.967 226.062 215.332 225.611 215.57C225.161 215.807 224.65 215.906 224.143 215.853C223.637 215.8 223.158 215.598 222.766 215.272C222.374 214.947 222.088 214.513 221.943 214.025C221.798 213.537 221.801 213.016 221.951 212.53C222.102 212.044 222.393 211.613 222.789 211.292C223.184 210.971 223.666 210.775 224.173 210.727C224.51 210.694 224.85 210.729 225.174 210.828C225.498 210.927 225.799 211.09 226.059 211.306C226.32 211.523 226.535 211.789 226.692 212.089C226.85 212.389 226.946 212.717 226.975 213.055Z" fill="#1E1E1E" />
            <path d="M226.975 232.432C227.022 232.939 226.917 233.449 226.674 233.897C226.432 234.344 226.062 234.71 225.611 234.947C225.161 235.184 224.65 235.283 224.143 235.23C223.637 235.177 223.158 234.975 222.766 234.65C222.374 234.324 222.088 233.891 221.943 233.402C221.798 232.914 221.801 232.394 221.951 231.907C222.102 231.421 222.393 230.99 222.789 230.669C223.184 230.348 223.666 230.152 224.173 230.105C224.51 230.072 224.85 230.107 225.174 230.206C225.497 230.306 225.798 230.469 226.059 230.685C226.319 230.901 226.534 231.167 226.692 231.467C226.849 231.767 226.945 232.095 226.975 232.432Z" fill="#1E1E1E" />
            <path d="M104.25 451.445L167.5 476.05L184.93 431.165C185.945 428.693 187.638 426.56 189.815 425.01C191.992 423.46 194.563 422.56 197.23 422.41C199.898 422.26 202.554 422.87 204.89 424.168C207.226 425.465 209.146 427.397 210.43 429.74C213.358 435.117 217.762 439.545 223.122 442.505C228.483 445.463 234.577 446.83 240.687 446.442C246.798 446.052 252.67 443.928 257.612 440.315C262.557 436.703 266.365 431.753 268.59 426.05C270.815 420.345 271.365 414.122 270.173 408.117C268.98 402.112 266.098 396.573 261.865 392.15C257.63 387.725 252.22 384.605 246.273 383.153C240.325 381.7 234.086 381.977 228.29 383.952C225.758 384.825 223.03 384.96 220.424 384.343C217.818 383.725 215.44 382.38 213.569 380.465C211.697 378.548 210.409 376.14 209.853 373.52C209.297 370.9 209.497 368.175 210.43 365.665L222.21 335.415" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M65.67 436.438L21.355 419.188C19.6593 418.528 18.1101 417.54 16.7961 416.283C15.482 415.023 14.4288 413.517 13.6965 411.852C12.9643 410.187 12.5674 408.393 12.5286 406.573C12.4897 404.755 12.8096 402.945 13.47 401.25L65.0525 268.675C65.7122 266.978 66.7002 265.425 67.96 264.11C69.22 262.795 70.727 261.74 72.3947 261.008C74.0625 260.275 75.8582 259.88 77.6792 259.843C79.5005 259.805 81.311 260.128 83.0075 260.79L182.507 299.453" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M417.44 183.557C417.735 184.689 418.183 185.776 418.77 186.787" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M417.307 176.957C417.727 174.957 418.577 173.071 419.797 171.431C421.017 169.79 422.58 168.435 424.375 167.457C429.75 164.529 434.18 160.125 437.137 154.765C440.095 149.405 441.46 143.312 441.072 137.202C440.682 131.092 438.557 125.22 434.945 120.278C431.332 115.336 426.382 111.528 420.68 109.303C414.975 107.079 408.755 106.53 402.75 107.722C396.745 108.913 391.205 111.795 386.782 116.029C382.36 120.263 379.24 125.671 377.787 131.619C376.337 137.566 376.612 143.805 378.587 149.6C379.462 152.132 379.597 154.861 378.982 157.467C378.365 160.074 377.02 162.452 375.102 164.324C373.185 166.195 370.777 167.483 368.155 168.038C365.535 168.593 362.81 168.392 360.3 167.457L312.232 148.792L299.732 180.947" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M363.293 351L395.543 363.5C397.24 364.162 399.05 364.485 400.87 364.448C402.69 364.41 404.488 364.012 406.155 363.28C407.823 362.547 409.328 361.495 410.588 360.18C411.848 358.863 412.835 357.313 413.495 355.615L465.03 223.048C465.693 221.351 466.015 219.541 465.978 217.72C465.94 215.899 465.545 214.104 464.813 212.436C464.08 210.769 463.025 209.262 461.71 208.002C460.395 206.742 458.845 205.755 457.148 205.095L425.845 192.983C424.568 192.474 423.368 191.786 422.283 190.94" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M257 240.08C260.205 240.325 263.237 241.63 265.617 243.789C267.997 245.947 269.592 248.837 270.15 252.002" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M203.938 181.087C209.933 175.038 218.078 171.606 226.595 171.54" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M243.883 120.955L170.025 92.2675" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M282.357 181.705L297.887 141.997L268.25 130.455" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M194.295 284.11L90.085 243.595C86.6625 242.255 83.9108 239.612 82.4328 236.246C80.9548 232.881 80.8713 229.067 82.2 225.64L96.07 189.685C96.965 187.172 97.1385 184.459 96.571 181.852C96.0035 179.246 94.7175 176.851 92.8588 174.937C91 173.024 88.6425 171.67 86.0538 171.028C83.4648 170.385 80.7475 170.48 78.21 171.303C72.4285 173.255 66.2098 173.518 60.2845 172.059C54.359 170.6 48.973 167.48 44.7588 163.067C40.5445 158.653 37.6773 153.129 36.4938 147.142C35.31 141.156 35.8593 134.956 38.077 129.271C40.2945 123.586 44.0883 118.651 49.0128 115.048C53.9373 111.444 59.7878 109.32 65.8773 108.926C71.967 108.531 78.0428 109.882 83.3908 112.821C88.739 115.76 93.1375 120.164 96.07 125.515C101.593 135.658 117.18 135.515 121.57 124.137L123.47 119.245" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M225.798 166.75C227.69 166.369 229.534 165.782 231.298 165L226.75 170.163" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M259.7 181.325C254.39 181.211 249.149 180.083 244.263 178C241.088 176.277 237.995 173.155 238.135 162.135C238.261 157.261 238.753 152.403 239.608 147.602C246.21 145.417 243.23 137.712 238.753 140.715C237.105 141.82 235.91 142.507 235.618 140.715C233.808 129.537 225.313 126.56 219.993 136.63L218.33 140.477C214.48 151.312 213.905 168.615 225.798 166.742C226.262 167.835 226.578 168.985 226.738 170.162C226.8 171.35 225.5 177.712 224.078 178.522C221.175 180.177 216.145 181.752 207.12 181.325" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M365.64 208.138C391.612 205.865 396.657 204.298 413.39 201.798C415.1 201.56 417.097 201.185 419.375 200.705C427.787 198.925 428.4 196.205 418.567 196.335C413.575 196.401 408.6 195.745 403.795 194.388" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M407.37 195.25C407.418 195.487 413.643 193.59 421.193 191.25C429.648 188.64 430.168 185.202 419.91 186.642C414.74 187.626 409.438 187.722 404.235 186.928" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M402.433 181.087C402.433 181.087 421 175.947 422.143 175.587C430.893 172.755 432.213 175.922 424.755 179.91C417.915 183.567 408.795 187.41 408.795 187.41" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M362.433 179.44C375.668 178.13 374.313 178.237 374.408 178.237C377.875 178 382.305 175.342 382.815 172.3C383.69 167.08 384.048 159.347 386.663 159.76C390.695 160.4 389.103 171.67 391.745 175.387C392.653 176.665 397.113 175.387 398.823 174.675C401.628 173.497 411.025 169.442 411.93 169.022C420.348 165.14 421.443 168.615 414.78 172.87C411.473 174.874 408.458 177.324 405.818 180.15" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M400.817 177.383C403.947 183.349 404.87 190.229 403.427 196.81" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M396.305 181.135C395.687 188.83 392.305 194.15 385.095 195.575" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M260.84 222.033C261.75 218.017 262.925 214.066 264.355 210.205L272.65 222.318" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M239.875 314.115L230.965 284.538L244.737 308.145" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M212.867 259.792C163.85 323.725 136.117 363.667 71.3672 423.992C61.6172 433.085 90.0047 464.195 99.8672 455.293L116.867 439.952C163 398.25 192.775 370.605 229.25 327.05C229.25 327.05 243.117 310.188 244.732 308.145L269.29 350.275C272.002 354.947 276.087 358.672 280.99 360.942C285.895 363.21 291.377 363.915 296.695 362.957L392.695 345.708C401.292 344.275 394.172 304.423 385.427 305.958L300.025 318.687C300.025 318.687 293.382 289.438 292.275 284.393C290.3 275.213 285.872 266.737 279.467 259.87C273.06 253.002 264.915 248 255.892 245.392" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M220 136.582C225.035 126.702 234.25 118.725 249.352 136.012C265.5 154.49 238.143 162.183 238.143 162.183" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M266.825 131.785C261.22 137.628 252.445 138.328 247.207 133.305C241.97 128.283 242.267 119.483 247.872 113.64C253.477 107.798 262.252 107.098 267.49 112.12C272.727 117.143 272.43 125.943 266.825 131.785Z" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M90.7498 455.863C87.0448 459.613 84.1398 462.823 80.2998 464.033C76.8798 465.03 73.298 465.352 69.7548 464.982C54.7898 463.79 24.9748 477.045 23.9673 468.925C23.6848 466.645 32.8498 459.9 43.6798 452.675C50.5198 448.115 60.8748 440.232 69.9923 433.06" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M390.843 310.14C396.875 308.572 401.528 306.822 405.945 307.86C409.388 308.735 412.628 310.262 415.493 312.36C427.783 320.955 460.21 324.65 457.008 332.165C456.098 334.3 444.758 335.445 431.785 336.202C422.855 336.727 408.415 338.482 396.21 340.202" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M367.225 174.202C369.005 174.055 370.935 175.452 372.647 178.422" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M374.55 207.307C373.505 209.587 372.08 211.012 370.465 211.155" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M310.665 83.7175C310.735 84.1385 310.64 84.5708 310.4 84.923C310.157 85.2753 309.787 85.5203 309.37 85.6063C308.952 85.6923 308.517 85.613 308.155 85.3848C307.795 85.1565 307.537 84.7972 307.435 84.3825L303.397 64.6325C303.31 64.2103 303.392 63.7705 303.627 63.4097C303.865 63.049 304.235 62.7968 304.657 62.7088C305.077 62.6205 305.517 62.7035 305.88 62.9395C306.24 63.1758 306.492 63.5453 306.58 63.9675L310.665 83.7175Z" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M302.638 52.795L305.013 64.29" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M296.795 24.2499C293.973 24.828 291.385 26.23 289.36 28.2785C287.333 30.3273 285.96 32.9305 285.415 35.7593C284.868 38.588 285.173 41.5153 286.29 44.1708C287.408 46.8263 289.288 49.0907 291.693 50.6782C294.095 52.2655 296.918 53.1042 299.798 53.0885C302.68 53.0727 305.49 52.203 307.878 50.5893C310.265 48.9758 312.118 46.6907 313.208 44.023C314.295 41.3555 314.568 38.425 313.99 35.6025C313.608 33.7277 312.858 31.9467 311.788 30.3615C310.715 28.7762 309.343 27.4177 307.745 26.3635C306.148 25.3092 304.36 24.5798 302.48 24.2172C300.603 23.8546 298.67 23.8657 296.795 24.2499Z" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M471.54 290.807L465.982 289.145" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M478.285 287.103L479.9 281.545" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M473.582 303.063L475.245 297.553" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M481.99 293.848L487.5 295.462" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M482 293.848C480.615 293.44 479.45 292.503 478.755 291.238C478.06 289.973 477.895 288.485 478.295 287.098C477.887 288.483 476.95 289.648 475.685 290.343C474.42 291.038 472.932 291.203 471.545 290.803C472.93 291.21 474.095 292.148 474.79 293.413C475.485 294.678 475.65 296.165 475.25 297.553C475.657 296.168 476.595 295.003 477.86 294.308C479.125 293.613 480.612 293.448 482 293.848Z" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M128.938 136.25C126.085 129.404 123.983 122.269 122.668 114.97" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M139.482 111.313L105.665 118.58C104.09 118.92 102.445 118.62 101.091 117.747C99.737 116.874 98.7852 115.499 98.445 113.925L84.1475 47.285L104.762 42.8675C105.913 42.6302 107.106 42.727 108.203 43.146C109.3 43.5652 110.255 44.2893 110.954 45.233C111.653 46.1765 112.067 47.3005 112.149 48.472C112.23 49.6435 111.974 50.8138 111.412 51.845C110.154 54.2138 109.598 56.8923 109.811 59.566C110.023 62.2398 110.994 64.7975 112.61 66.9382C114.226 69.0787 116.419 70.7138 118.933 71.6503C121.446 72.587 124.174 72.7865 126.797 72.2258C129.42 71.6648 131.828 70.3665 133.739 68.4837C135.649 66.601 136.982 64.212 137.581 61.5975C138.18 58.9832 138.02 56.252 137.12 53.7255C136.22 51.1988 134.617 48.9818 132.5 47.335C131.571 46.6208 130.864 45.6575 130.462 44.5573C130.059 43.457 129.978 42.265 130.227 41.12C130.475 39.9752 131.045 38.9245 131.867 38.0908C132.69 37.2568 133.733 36.674 134.875 36.41L156.962 31.6125L171.307 98.25C171.647 99.8245 171.348 101.47 170.475 102.824C169.602 104.178 168.227 105.129 166.652 105.47L146.18 109.888" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M138.057 130.17C135.35 126.845 134.447 118.818 139.197 115.17C139.197 115.17 139.83 101.74 142.142 98.69C144.455 95.64 145.75 108.275 146.75 113.07C147.407 116.208 149.125 123.472 150.835 129.457" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M193.128 221.5L200.66 203.25C198.193 209.125 197.372 215.561 198.285 221.867" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M367.225 174.202C325.62 179.615 283.115 183.177 259.7 181.325C240.89 185.98 223.223 186.502 207.12 181.325C198.808 180.945 187.17 178.76 170.785 173.297L157.343 130.455C155.918 125.8 122.953 135.87 124.378 140.525L146.18 208.067C162.361 216.346 180.129 221.058 198.285 221.887C199.425 230.057 203.368 241.6 212.868 259.792C223.08 265.872 245.118 257.18 255.618 246.255C257.47 237.705 259.133 229.203 260.843 222.033C297.69 223.281 334.538 219.622 370.42 211.155C360.63 212 357.25 175.045 367.225 174.202Z" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M82.9995 412.877C75.542 419.377 103.75 451.627 111.167 445.175" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M368.043 308.573C374.598 307.385 381.913 347.662 375.358 348.85" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M339.117 421.285L321.162 467.5" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M353.27 453.345L307.008 435.44" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M17.7929 261.455C18.7429 258.748 20.4528 257.133 25.3928 253.76C35.8503 246.62 33.8928 238.433 27.7203 235.995C26.6293 235.574 25.4591 235.397 24.2922 235.477C23.1253 235.557 21.9901 235.891 20.9666 236.458C19.9431 237.024 19.0562 237.807 18.3683 238.753C17.6804 239.699 17.2084 240.784 16.9854 241.933" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M96.4502 439C144.85 393.658 190.888 345.86 234.383 295.793" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M244.75 254.568C244.75 254.568 270.155 265.777 272.537 286.152C273.862 297.505 281.372 320.97 285.362 332.938C286.052 335.013 287.445 336.785 289.3 337.945C291.155 339.107 293.355 339.587 295.525 339.302L376.795 328.662" stroke="#1E1E1E" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
}
export default MainBg;