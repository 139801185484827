import styled from 'styled-components'

export const AboutContainer = styled.div`
color: #1E1E1E;
`
export const AboutWrapper = styled.div`
// z-index: 1;
// height: 860px;
width: 100%;
max-width: 70vw;
margin-top: 18vh;
margin-bottom: 15vh;
margin-right: auto;
margin-left: auto;
padding :0 9vw;
justify-content: center;
font-weight: 400;
font-size: 38px;
line-height: 46px;
text-align: center;
color: #1E1E1E;
position: relative;

@media screen and (max-width:768px){
    margin-top: 8vh;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    max-width: 100%;
    padding :0 13vw;
    margin-bottom: 8vh;
}

@media screen and (min-width:768px) and (max-width:1300px){
    font-weight: 400;
    font-size: 30px;
    line-height: 44px;
    max-width: 80%;
    padding :0 13vw;
}

@media screen and (min-height:1500px)and (max-height:3000px) {
margin-top: 7vh;
margin-bottom: 5vh;
}
`
export const AboutContent = styled.div`
display: flex;
// justify-content: center;
align-items: center;
flex-direction: column;
height: 370px;
background: #FAEDCD;
width: 100%;

@media screen and (max-width:768px){
    height: 300px;
}

@media screen and (min-width:768px) and (max-width:1300px){
    height: 340px;
}
`
export const ContentHead = styled.div`
margin-top:10vh;
width: 100%;
background: #FAEDCD;
color: #866646;
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 50px;
line-height: 61px;
text-align: center;

@media screen and (max-width:768px){
    margin-top: 6vh;
    font-weight: 600;
    font-size: 35px;
    line-height: 61px;
}

@media screen and (min-width:768px) and (max-width:1300px){
    margin-top:10vh;
    font-size: 44px;
    line-height: 54px;
}

@media screen and (min-height:1500px)and (max-height:3000px) {
    margin-top:3vh;
}

` 