import { Hire, Feasibility, TechStack, BuildingProducts, Connelly } from '../images/ServiceIcons';

export const services = [{
    logo: Hire,
    heading: 'CTO for Hire',
    description: 'Partner with us today and realise the venture that\'s been keeping you awake! Not personally knowing someone in the tech business should not be a stumbling block to building a solution.'
},
{
    logo: Feasibility,
    heading: 'Feasibility Analysis',
    description: 'Talk to our experts today to vet your ideas. Project MIMIR provides experts from the vertical of your choosing. The right credentials for the right solution.'
},
{
    logo: TechStack,
    heading: 'Tech Stack Review',
    description: 'We offer top notch audit services that will check your stack for performance and scale. Talk to us to know how best to grow your stack so it keeps up with your needs.'
},
{
    logo: BuildingProducts,
    heading: 'Building products',
    description: 'The heart of our collective. We would love to build your solution for you! Distributed teams with diverse capabilities work using the best stack for you.'
}]

// export const services = [{
//     logo: Hire,
//     heading: 'CTO for Hire',
//     description: 'Partner with us today and realise the venture that\'s been keeping you awake!'
// },
// {
//     logo: Feasibility,
//     heading: 'Feasibility Analysis',
//     description: 'Project MIMIR provides experts from the vertical of your choosing.'
// },
// {
//     logo: TechStack,
//     heading: 'Tech Stack Review',
//     description: 'We offer top notch audit services that will check your stack for performance and scale.'
// },
// {
//     logo: BuildingProducts,
//     heading: 'Building products',
//     description: 'The heart of our collective. We would love to build your solution for you!'
// }]

export const testimonials = [{
    logo: Connelly,
    content: ' vestibulum nunc, vel viverra molestie imperdiet morbi non id. Rutrum sit massa congue amet varius et consectetur. Dignissim vestibulum habitasse ultricies. Kelly Leannon Founder & COO',
    baseHead: 'Kelly Leannon',
    desg:'Founder & COO'
},
{
    logo: Connelly,
    content: ' vestibulum nunc, vel viverra molestie imperdiet morbi non id. Rutrum sit massa congue amet varius et consectetur. Dignissim vestibulum habitasse ultricies. Kelly Leannon Founder & COO',
    baseHead: 'Kelly Leannon',
    desg:'Founder & COO'
},]
