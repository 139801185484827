import React, { useState } from 'react'
import { ContactContainer, ContactContent, ContactGrid1, ContactGrid2 } from './Elements'
import { ContactButton } from '../MainSection/Elements'
import { Contact } from '../../images/Contact'
import { Feather7 } from '../../images/Feathers'
import './contact.css'
import ContactDialog from './ContactDialog';

export const ContactSection = () => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <ContactContainer id='contact'>
                <ContactContent className='mainContact'>
                    {/* <ContactButton href='https://rdj4l7hx3mb.typeform.com/to/GNnubGVs' target='_blank'>Contact</ContactButton> */}
                    <ContactGrid1>
                        <div className='contactHeadMain'>
                            <div className='contactHeadM'>Have a project ?</div>
                            <div className='contactSubHead'>We will be happy to have a discussion.</div>
                            <ContactButton className='contactBtn' onClick={handleClickOpen} >Contact</ContactButton>
                        </div>
                    </ContactGrid1>
                    <ContactGrid2>
                        <Contact />
                        <div className='feather7'>
                            <Feather7 />
                        </div>
                    </ContactGrid2>
                </ContactContent>
                <ContactDialog open={open}
                    handleClose={handleClose} />
            </ContactContainer>
        </>
    )
}
