import React from 'react'
import { SidebarContainer, SidebarWrapper, SidebarMenu, SidebarLink } from './Elements'

export const Sidebar = ({toggle, isOpen}) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to='about' smooth={true} duration={700} spy={true} exact='true' offset={-80} onClick={toggle}>
                        About
                    </SidebarLink>
                    <SidebarLink to='services' smooth={true} duration={700} spy={true} exact='true' offset={-80} onClick={toggle}>
                        Services
                    </SidebarLink>
                    <SidebarLink to='contact' smooth={true} duration={700} spy={true} exact='true' offset={-80} onClick={toggle}>
                        Contact
                    </SidebarLink>
                </SidebarMenu>
                
            </SidebarWrapper>
        </SidebarContainer>
    )
}
